<template>
  <PageHeader/>
  <router-view v-slot="{Component}">
    <transition name="fade">
      <component :is="Component" :key="$route.path"></component>
    </transition>
  </router-view>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import {defineComponent} from "vue";

export default defineComponent({
  components: {
    PageHeader
  },
})

</script>

<style>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 1s;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
  transform: translateY(-20px);
}

</style>
<template>
    <h1>{{ msg }}</h1>
</template>

<script>

export default {
  name: 'WelcomeHeading',
  props: {
    msg: String
  },
}
</script>

<template>
  <li :id="id" class="border-solid	border-b border-b-black/20 py-6 last-of-type:border-b-0 last-of-type:mb-0">
    <div class="grid grid-cols-12 gap-6 sm:gap-3 items-center">
      <div class="sm:col-span-3 col-span-6">
        <p class="text-sm font-semibold text-black flex flex-row gap-x-2 items-center">
            <IconWorld/>
          {{ title }}
        </p>
      </div>
      <div class="sm:col-span-3 col-span-6 order-4 sm:order-2">
        <p class="text-sm text-black ">
          {{ description }}
        </p>
      </div>
      <div class="sm:col-span-2 col-span-6 justify-end sm:justify-normal flex flex-row gap-x-2 items-center order-2 sm:order-3">
        <p class="text-sm text-black" v-if="!password">
            ***************
        </p>
        <p class="text-sm text-black font-semibold" v-else-if="password">
          {{ password }}
        </p>
        <button @click="showPassword"  class="cursor-pointer">
          <IconEye/>
        </button>
      </div>
      <div class="sm:col-span-4 col-span-12 order-3 sm:order-4">
        <a class="text-base sm:justify-end text-right font-bold hover:no-underline underline flex flex-row gap-x-2 items-center" :href="url" target="_blank">
          {{ url }}
          <span><IconUrl/></span>
        </a>
      </div>
    </div>
  </li>
</template>
<script>

import IconEye from "@/components/icons/IconEye.vue";
import IconUrl from "@/components/icons/IconUrl.vue";
import IconWorld from "@/components/icons/IconWorld.vue";
import {getCookie} from "@/assets/scripts/cookie";
import axios from "axios";

export default {
  name: 'ListItem',
  components: {
    IconEye, IconUrl, IconWorld
  },
  props: {
    id: String,
    url: String,
    title: String,
    description: String,
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      password: ''
    }
  },
  methods: {
    showPassword() {

      let token = getCookie('token');

      if (!token) return;

      let id = this.$props.id

      axios.get(`${this.backendUrl}api/user/auths/${id}`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
          .then((response) => {
            if (response.data.success) {
              this.password = response.data.password;
            }
          })
          .catch((err) => {
            console.warn(err);
          })
    }
  }
}
</script>
import {createApp} from 'vue';
import App from './App.vue'
import router from './router'
import {gsap} from "gsap";
const app = createApp(App);
import '@/assets/styles/base.scss';

import {createI18n} from "vue-i18n";
import translations from "@/assets/scripts/translations";

app.use(router).mount('#app');

const i18n = createI18n({
    locale: "en",
    fallbackLocale: "en",
    messages: {
        ...translations
    }
})

// global

app.config.globalProperties.$gsap = gsap;

app.config.globalProperties.$goToUrl = (path) => {
    let paramsObj = router.currentRoute.value.params;

    let dynamicPath = '';

    if (path) {
        dynamicPath += `/${path}`;
    }

    dynamicPath += `/${paramsObj.id}/${paramsObj.hash}/${paramsObj.source}`;

    if (paramsObj.lang) {
        dynamicPath = `/${paramsObj.lang}${dynamicPath}`;
    }

    router.push({ path: dynamicPath });
};

app.config.globalProperties.$handleLanguageByUrl = () => {
    let paramsObj = router.currentRoute.value.params;

    if (!paramsObj.lang) return;

    i18n.global.locale = paramsObj.lang;
}

app.use(i18n);
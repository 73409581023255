<template>
  <section class="home container lg pt-40 pb-10">
    <div class="flex w-full a-center flex-row justify-between mb-10">
      <WelcomeHeading class="js-fade-in" msg="Hello!"/>
      <MainButton class="!m-0 js-fade-in" @click="showModal = true; formSent = false" title="Add a login"/>
    </div>
    <div class="js-fade-in w-full bg-white sm p-4 sm:p-8 rounded-lg shadow-2xl">
      <div class="flex items-center justify-between mb-4">
        <h5 class="text-xl font-bold leading-none text-black">Logins list</h5>
        <a href="#" class="text-sm font-medium text-blue hover:underline">
          View all
        </a>
      </div>
      <div class="flow-root overflow-auto max-h-[500px]">
        <ul role="list">
          <ListItem :key="auth.id" v-for="auth in auths" :id="auth.id" :description="auth.description" :url="auth.url" :title="auth.name"></ListItem>
        </ul>
      </div>
    </div>
    <div v-if="showModal"
         class="modal-overlay flex items-center justify-center fixed top-0 left-0 h-100 w-full min-h-screen bg-black/50 bg-opacity-50">
      <div class="modal sm:w-[600px] w-[calc(100%-32px)] bg-white h-auto rounded-lg shadow p-10 relative">
        <button @click="showModal = false" class="absolute flex justify-center items-center top-5 right-5">
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="none" viewBox="0 0 25 25">
            <path stroke="#0A0908" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"
                  d="m6.25 6.25 12.5 12.5m0-12.5-12.5 12.5"/>
          </svg>
        </button>
        <div v-if="!formSent">
          <h2 class="text-center mb-10">Add a Login</h2>
          <FormInput v-model="name" type="text" name="name" placeholder="Name of login"/>
          <FormInput v-model="url" type="text" name="url" placeholder="Url of login"/>
          <FormInput v-model="password" type="password" name="password" placeholder="Password of login"/>
          <FormInput v-model="description" type="text" name="name" placeholder="Description of login"/>
          <p class="mx-auto mb-4 text-red text-center" v-if="message">{{ message }}</p>
          <MainButton @click="postAuth" title="Add a Login"/>
        </div>
        <div v-if="formSent">
          <h2 class="text-center">Login saved</h2>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import WelcomeHeading from '@/components/WelcomeHeading.vue';
import ListItem from "@/components/ListItem.vue";
import axios from "axios";
import {getCookie} from "@/assets/scripts/cookie";
import FormInput from "@/components/FormInput.vue";
import MainButton from "@/components/MainButton.vue";

export default {
  name: 'HomeView',
  components: {
    WelcomeHeading, ListItem, MainButton, FormInput
  },
  data() {
    return {
      auths: [],
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      name: '',
      description: '',
      url: '',
      password: '',
      showModal: false,
      formSent: false,
      message: ''
    }
  },
  computed: {
    pageTexts() {
      return {
        title: this.$t("hello"),
        about_us: this.$t("about_us")
      }
    }
  },
  beforeMount() {
    this.$handleLanguageByUrl();
  },
  mounted() {
    this.initIntro();
    this.getAuths();
  },
  methods: {
    initIntro() {
      this.$gsap.to(document.querySelectorAll('.js-fade-in'), {
        autoAlpha: 1,
        duration: 1,
        stagger: 0.1
      })
    },
    getAuths() {
      let token = getCookie('token');

      if (!token) return;

      axios.get(`${this.backendUrl}api/user/auths`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
          .then((response) => {
            if (response.data.length > 0) {
              this.auths = response.data
            }
          })
          .catch((err) => {
            console.warn(err);
          })
    },
    postAuth() {
      if (!this.name || !this.password) {
        this.message = 'Please provide both name and price.';
        return;
      }

      let token = getCookie('token');

      if (!token) return;

      axios.post(`${this.backendUrl}api/auths`, {
            name: this.name,
            url: this.url ?? '',
            description: this.description ?? '',
            password: this.password
          },
          {
            headers: {
              Authorization: 'Bearer ' + token
            }
          }).then((response) => {
        if (response.data.success) {
          this.formSent = true;

          this.getAuths();
        } else {
          this.message = 'Something went wrong';
        }
      })
    }
  },
}
</script>

<style lang="sass">
@import "../assets/styles/views/home"
</style>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" class="text-black" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fill-rule="evenodd"
          d="M12 10a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-2.828-.828a4 4 0 1 1 5.656 5.656 4 4 0 0 1-5.656-5.656Z"
          clip-rule="evenodd"/>
    <path fill="currentColor" fill-rule="evenodd"
          d="m2.458 12-.954.3C2.905 16.762 7.074 20 12 20c4.927 0 9.095-3.239 10.496-7.7a1 1 0 0 0 0-.6C21.095 7.24 16.927 4 12 4 7.074 4 2.905 7.239 1.504 11.7l.954.3Zm1.054 0a9.005 9.005 0 0 0 16.976 0 9.004 9.004 0 0 0-16.976 0Z"
          clip-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'IconEye'
}
</script>
<template>
  <div class="container md h-full min-h-screen flex a-center">
    <div class="mx-auto w-full flex flex-col items-center justify-center">
      <div class="bg-white px-6 py-8 rounded-lg  drop-shadow-2xl text-black w-full">
        <h1 class="mb-8 text-3xl text-center text-black">Sign up</h1>
        <FormInput type="text" name="Name" placeholder="Your full name" v-model="name"/>
        <FormInput type="text" name="Email" placeholder="Email" v-model="email"/>
        <FormInput type="password" name="password" placeholder="Password" v-model="password"/>
        <FormInput type="password" name="confirm_password" placeholder="Confirm Password" v-model="confirmPassword"/>
        <p class="mx-auto mb-4 text-red text-center" v-if="message">{{ message }}</p>
        <MainButton @click="register" title="Create Account"></MainButton>
        <div class="text-center text-sm text-grey-dark mt-4">
          By signing up, you agree to the
          <a class="no-underline border-b border-grey-dark text-grey-dark" href="#">
            Terms of Service
          </a> and
          <a class="no-underline border-b border-grey-dark text-grey-dark" href="#">
            Privacy Policy
          </a>
        </div>
      </div>
      <div class="text-grey-dark mt-6">
        Already have an account?
        <router-link class="underline hover:no-underline text-blue" to="/login">
          Log in</router-link>.
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MainButton from "@/components/MainButton.vue";
import FormInput from "@/components/FormInput.vue";

export default {
  name: 'RegisterVue',
  components: {
    MainButton, FormInput
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      message: ''
    }
  },
  methods: {
    async register() {
      axios.post(`${this.backendUrl}api/register`, {
        name: this.name,
        email: this.email,
        password: this.password,
        password_confirmation: this.confirmPassword
      }).then((response) => {

        if (response.data.isCompleted) {
          this.$router.push({name: 'login'});
        }
      })
          .catch((response) => {
            this.message = response.response.data.message;
          })
    },
  }
}
</script>

<style lang="sass">
@import "../assets/styles/views/register"
</style>
<template>
  <section class="404-page section-block h-100 show">
    <h1>Error</h1>
  </section>
</template>

<script>
export default {
  name: 'PageNotFound',

}
</script>
<template>
  <section class="about section-block h-100 show">
    <WelcomeHeading :msg="pageTexts.about_us"/>
    <MainButton v-on:click="this.$goToUrl('')" class="mt-20 js-fade-in" :title="pageTexts.go_home"></MainButton>
  </section>
</template>

<script>
import WelcomeHeading from "@/components/WelcomeHeading.vue";
import MainButton from "@/components/MainButton.vue";
export default {
  name: 'AboutView',
  components: {
   WelcomeHeading, MainButton
  },
  beforeMount() {
    this.$handleLanguageByUrl();
  },
  mounted() {
    this.initAbout();
  },
  computed: {
    pageTexts() {
      return {
        title: this.$t("language"),
        go_home: this.$t("go_home"),
        about_us: this.$t("about_us")
      }
    }
  },
  methods: {
    initAbout() {
      this.$gsap.to(document.querySelectorAll('.js-fade-in'), {
        autoAlpha: 1,
        duration: 1,
        stagger: 0.3
      })
    },
  }
}
</script>

<style lang="sass">
     @import "../assets/styles/views/about"
</style>
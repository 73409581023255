<template>
  <div class="container md h-full min-h-screen flex a-center">
    <div class="mx-auto w-full flex flex-col items-center justify-center">
      <div class="bg-white px-6 py-8 rounded-lg drop-shadow-2xl text-black w-full">
        <h1 class="mb-8 text-center text-black">Login</h1>
        <FormInput type="text" name="email" placeholder="Email" v-model="email"/>
        <FormInput type="password" name="password" placeholder="Password" v-model="password"/>
        <FormInput v-if="showTokenInput" type="text" name="token" placeholder="Token" v-model="token"/>
        <p class="mx-auto mb-4 text-red text-center" v-if="message">{{ message }}</p>
        <MainButton @click="loginHandler" title="Login"></MainButton>
      </div>
      <div class="text-grey-dark mt-6">
        Don't have an account?
        <router-link class="underline hover:no-underline text-blue" to="/register">
          Register</router-link>.
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MainButton from "@/components/MainButton.vue";
import FormInput from "@/components/FormInput.vue";
import {setCookie} from "@/assets/scripts/cookie";

export default {
  name: 'LoginView',
  components: {
    MainButton, FormInput
  },
  data() {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      email: '',
      password: '',
      message: '',
      token: '',
      showTokenInput: false
    }
  },
  methods: {
    async loginHandler() {
      if (!this.email || !this.password) {
        this.message = 'Please provide both email and password.';
        return;
      }

      if (this.showTokenInput) {
        axios.post(`${this.backendUrl}api/login_step_2`, {
          email: this.email,
          password: this.password,
          twofa_token: this.token
        })
            .then((response) => {
              if (response.data.isLoggedIn) {
                setCookie('token', response.data.token, 30);

                this.$router.push({name: 'home'});

              } else {
                this.message = 'something went wrong';
              }
            })
            .catch((response) => {
              this.message = response.response.data.message;
            })
      } else {
        axios.post(`${this.backendUrl}api/login_step_1`, {
          email: this.email,
          password: this.password,
        })
            .then((response) => {
              if (response.data.tokenSend) {
                this.showTokenInput = true;
              } else {
                this.message = 'something went wrong';
              }
            })
            .catch((response) => {
              this.message = response.response.data.message;
            })
      }
    },
  }
}
</script>

<style lang="sass">
@import "../assets/styles/views/login"
</style>
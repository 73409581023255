const translations = {
    en: {
        hello: "Hello Iqos!",
        go_home: "Go home",
        about_us: "About us",
        error: "Error",
        Enter_text: "Įvesk savo gimimo datą, kad patvirtintum, jog esi suaugęs nikotino ar tabako gaminių vartotojas.",
        Month: "Mėnuo",
        Year: "Metai",
        January: "Sausis",
        February: "Vasaris",
        March: "Kovas",
        April: "Balandis",
        May: "Gegužės",
        June: "Birželis",
        July: "Liepa",
        August: "Rugpjūtis",
        September: "Rugsėjis",
        October: "Spalis",
        November: "Lapkritis",
        December: "Gruodis",
        Confirm: "Patikrinti",
        We_care: "Mums rūpi",
        description_one: "Šioje svetainėje yra pateikiama informacija apie bedūmius gaminius. Mes turime žinoti tavo amžių, kad įsitikintumėme, jog esi Lietuvoje gyvenantis pilnametis rūkantis asmuo, ketinantis ir toliau naudoti nikotino ar tabako gaminius. Mūsų nikotino ir tabako produktai nėra pagalbinė priemonė mesti rūkyti. Šie produktai nėra nerizikingi. Juose yra nikotino, kuris sukelia priklausomybę. Skirta tik suaugusiems vartotojams.",
        description_two: "Šioje Svetainėje pateikiama informacija apie gaminius, skirtus asmenims, vyresniems nei 18 metų.",
        information_text: "Norėdami sužinoti daugiau apie Philip Morris International ir mūsų gaminamus produktus aplankykite pmi.com"
    },
    ru: {
        hello: "こんにちは、世界",
        go_home: "家に帰れ",
        about_us: "私たちに関しては",
        error: "エラー",
        Enter_text: "Įvesk savo gimimo datą, kad patvirtintum, jog esi suaugęs nikotino ar tabako gaminių vartotojas.",
        Month: "Mėnuo",
        Year: "Metai",
        January: "Sausis",
        February: "Vasaris",
        March: "Kovas",
        April: "Balandis",
        May: "Gegužės",
        June: "Birželis",
        July: "Liepa",
        August: "Rugpjūtis",
        September: "Rugsėjis",
        October: "Spalis",
        November: "Lapkritis",
        December: "Gruodis",
        Confirm: "Patikrinti",
        We_care: "Mums rūpi",
        description_one: "Šioje svetainėje yra pateikiama informacija apie bedūmius gaminius. Mes turime žinoti tavo amžių, kad įsitikintumėme, jog esi Lietuvoje gyvenantis pilnametis rūkantis asmuo, ketinantis ir toliau naudoti nikotino ar tabako gaminius. Mūsų nikotino ir tabako produktai nėra pagalbinė priemonė mesti rūkyti. Šie produktai nėra nerizikingi. Juose yra nikotino, kuris sukelia priklausomybę. Skirta tik suaugusiems vartotojams.",
        description_two: "Šioje Svetainėje pateikiama informacija apie gaminius, skirtus asmenims, vyresniems nei 18 metų.",
        information_text: "Norėdami sužinoti daugiau apie Philip Morris International ir mūsų gaminamus produktus aplankykite pmi.com"
    }
}

export default translations;
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
    <path fill="currentColor" fill-rule="evenodd"
          d="M14 5a1 1 0 1 1 0-2h6a.997.997 0 0 1 1 .99V10a1 1 0 1 1-2 0V6.414l-8.293 8.293a1 1 0 0 1-1.414-1.414L17.586 5H14Zm5.617-1.924a.997.997 0 0 0-.322.215l.322-.215ZM3.88 5.879A3 3 0 0 1 6 5h4a1 1 0 1 1 0 2H6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-4a1 1 0 1 1 2 0v4a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V8a3 3 0 0 1 .879-2.121Z"
          clip-rule="evenodd"/>
  </svg>
</template>

<script>
export default {
  name: 'IconUrl'
}
</script>
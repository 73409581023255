<template>
  <header class="logo-header">
    <MainLogo/>
  </header>
</template>

<script>
import MainLogo from "@/components/MainLogo.vue";

export default {
  name: 'PageHeader',
  components: {
    MainLogo
  }
}

</script>

<style lang="sass" scoped>
  @import "../assets/styles/components/logo-header.scss"
</style>
import {createRouter, createWebHistory} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from "@/views/LoginView.vue";
import RegisterVue from "@/views/RegisterVue.vue";
import AboutView from "@/views/AboutView.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import axios from "axios";
import {getCookie} from '/src/assets/scripts/cookie';

const routes = [
    {
        path: '/register',
        name: 'register',
        component: RegisterVue,
        props: true,
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        props: true,
    },
    {
        path: '/',
        name: 'home',
        component: HomeView,
        props: true,
        meta: {requiresAuth: true}
    },
    {
        path: '/about',
        name: 'about',
        component: AboutView,
        props: true,
        meta: {requiresAuth: true}
    },
    {
        path: '/:catchAll(.*)*',
        name: "PageNotFound",
        component: PageNotFound
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

const checkLoggedIn = async () => {
    let token = getCookie('token');

    if (!token) return false;

    let backendUrl = process.env.VUE_APP_BACKEND_URL;

    try {
        let response = axios.get(`${backendUrl}api/user`, {
            headers: {
                Authorization: 'Bearer ' + token
            }
        })

        return response;

    } catch (err) {
        console.log(err);

        return false;
    }
}

router.beforeEach(async (to) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const isLoggedIn = await checkLoggedIn();

        if (!isLoggedIn) {
            return ({name: 'login'});
        }
    }
});

export default router
<template>
  <input
      :type="type"
      :name="name"
      :placeholder="placeholder"
      :value="modelValue" @input="$emit('update:modelValue', $event.target.value)"
      class="block border-2 border-blue bg-white w-full p-3 rounded mb-4">
</template>

<script>
export default {
  name: 'FormInput',
  props: {
    type: String,
    name: String,
    value: String,
    placeholder: String,
    modelValue: {
      type: String,
      default: '',
      required: true,
    },
  },
}
</script>
<template>
  <button class="button">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'MainButton',
  props: {
    title: String
  }
}
</script>